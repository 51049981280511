import React, { Component } from "react";
import "../styles/styles.css";

export default class Footerscreen extends Component {
  render() {
    return (
      <div>
        <div className="footerbg">
          <div className="footerlogo">
            {" "}
            <img
              className="img-fluid"
              src="../../assets/logo-yoofoo-gray-scale.png"
              alt="Logo"
            ></img>
          </div>
          <div className="col-md-12 col-sm-12">
            <div className="caretojoin">Care To Join Us?</div>
            <div className="subhdr_blue">
              YooFoo is seeking passionate individuals for the following
              posistions:
            </div>
            <div className="subhdr_wt">Software Developers (USA, INDIA)</div>
            <div className="subhdr_wt">Administrative Assistant (USA)</div>
            &nbsp;&nbsp;
            <div className="footer_br1"></div>
          </div>
          <div className="footer_br"></div>
          <div className="footer_br1"></div>
          <div className="col-md-12">
            <div className="row footercopy">
              <div className="col-md-3"></div>
              <div className="col-md-5">
                Copyright © {new Date().getFullYear()} YooFoo All Rights
                Reserved
              </div>
              <div className="col-md-4">
                Follow Us :
                <a href="https://www.facebook.com/pages/Yoofoo/258692607528105">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.linkedin.com/company/yoofoo-com-llc">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
