import React, { Component } from "react";
import scrollToComponent from "react-scroll-to-component";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../styles/fontstyle.css";
import "../styles/styles.css";
import "../styles/slidernews.css";
import Footerscreen from "../screens/footer";

const content = [
  {
    title: (
      <p className="h1_hdr">
        Digital <div>Transformation</div> <div> Solutions</div>
      </p>
    ),
    description: "Through Distributed Agile Team",
    image: "../assets/banner1.jpg",
  },
  {
    title: (
      <p className="h1_hdr">
        Software, <div>and</div> <div>Data Solutions</div>
      </p>
    ),
    description: "A Respected World Leader in the Provision of Technology",
    image: "../assets/about.jpg",
  },
  {
    title: (
      <p className="h1_hdr">
        Agile <div>Project Management </div> <div> Style </div>
      </p>
    ),
    description: "Keeping Your Needs As Our Priority",
    image: "../assets/banner2.jpg",
  },
  {
    title: "Software Engineers ",
    description: "- Hire As You Need Them",
    image: "../assets/contactus.jpg",
  },
];

const Industry_Expertise = [
  {
    Industry_title: "BFSI",
    Industry_title1: "BANKS, FINANCIAL SERVICES AND INSURANCE",
    Industry_logo: "../assets/clients/bfsi.png",
  },
  {
    Industry_title: "LIFE SCIENCES",
    Industry_title1: "BIOTECH, DIAGNOSTICS AND WELLNESS",
    Industry_logo: "../assets/clients/lifesciences.png",
  },
  {
    Industry_title: "HIGH TECH",
    Industry_title1: "SILICON VALLEY TO SILICON FOREST",
    Industry_logo: "../assets/clients/hightech.png",
  },
];

const content_Reviews = [
  {
    title: "2015 Global Big Data Competition",
    description: "Brad Stone",
    company: "By Global Data Utah",
    description1:
      "Jeff Boonmee, CEO at YooFoo.com, is awarded an Honorable Mention for providing leadership in more than one region (Greater India and South East Asia). Participation in the Greater India region ranked second overall – only beat by the Intermountain West region.",

    title2: "Amazing Partner!",
    description2: "Vince Romney",
    company2: "By Forevergreen International",
    description12:
      "YooFoo has always been a great development partner and accommodates the rigors of the direct-selling industry with great efficiency. Definitely recommend YooFoo as a great asset to any development needs",
  },
  {
    title: "Thanks!",
    description: "Daniel Zaldivar",
    company: "By Unicity International",
    description1:
      "...very knowledgable on technology matters. Wonderful team of developers fluent in many different programming languages and technologies but are able to makes you feel confortable when discussing serious complex technologies. It is a pleasure to work and associate with YooFoo.",

    title2: "Amazing!",
    description2: "",
    company2: "",
    description12:
      "Overall my experience has been positive. My interactions with Jeff B. have been extremely helpful, allowing me to feel comfortable with every step in the process. I also am afforded the opportunity to provide him with realtime feedback on the engineers.",
  },
  {
    title: "Thanks!",
    description1:
      "The service was fairly priced. Systems were in place and developers were engaged and producing quickly.",

    image2: "../assets/profile_img/testimonial-2.png",
    title2: "Positive Feedback!",
    description12:
      "A positive is their flexibility to work with a team that is ever changing and moving at a very quick pace.",
  },
];

export default class Homescreen extends Component {
  componentDidMount() {
    scrollToComponent(this.Homescreen, {
      offset: 0,
      align: "top",
      duration: 500,
      ease: "inCirc",
    });
  }
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      name: "",
      phone: "",
      email: "",
      message: "",
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  sendContact() {
    console.log("sendContact", this.state.name);
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: this.state.name,
        phone: this.state.phone,
        email: this.state.email,
        message: this.state.message,
      }),
    };
    fetch(
      "https://hak82oy2x6.execute-api.us-east-1.amazonaws.com/sendContactForm",
      requestOptions
    ).then((response) => {
      if (response.ok) {
        alert("Thanks for reaching out to us! We'll get back to you!");
        this.setState({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        response.text().then((text) => {
          alert("Error : " + text);
        });
      }
    });
  }

  render() {
    const BASE_URL = "#";
    console.log("sendContact", this.state.name);
    return (
      <div>
        <div
          className="modal fade bd-example-modal-lg"
          id="modalabout"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  About Us
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12">
                    <p className="about_para">
                      <span className="yoo">Yoo</span>
                      <span className="foo">Foo</span> is a minority-owned
                      company which was founded in 2010.
                      <span className="yoo">Yoo</span>
                      <span className="foo">Foo</span>'s primary aim is to use
                      technical skills and experience to make a difference in
                      people and businesses.
                    </p>
                    <p className="about_para paddingt0">
                      <span className="yoo">Yoo</span>
                      <span className="foo">Foo</span> has its offices in the US
                      (Headquarters) and international office in India
                      (International). The international office is occupied by
                      the <span className="yoo">Yoo</span>
                      <span className="foo">Foo</span> Technologies India
                      Private Limited.
                      <span className="yoo">Yoo</span>
                      <span className="foo">Foo</span> Technologies India
                      Private Limited was registered in September 2014. Their
                      registered address on file is Vatika Business Centre, 6th
                      Floor, Omega-C Block, Divyasree Building, Hitech City Rd,
                      Kondapur, Hyderabad - 500081, Telangana, India. Plans are
                      underway to establish more international offices in
                      Thailand, and opportunities in other regions are currently
                      being explored.
                    </p>
                  </div>
                  <div>
                    <div className="containertab">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#Vision"
                          >
                            <div className="floatleft iconbox">
                              <i
                                className="fa fa-eye vision"
                                aria-hidden="true"
                              ></i>
                            </div>
                            Vision
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#Mission"
                          >
                            <div className="floatleft iconbox">
                              <i
                                className="fa fa-podcast vision"
                                aria-hidden="true"
                              ></i>
                            </div>
                            Mission
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#Primary"
                          >
                            <div className="floatleft iconbox">
                              <i
                                className="fa fa-life-ring vision"
                                aria-hidden="true"
                              ></i>
                            </div>
                            Primary Objectives
                          </a>
                        </li>
                      </ul>

                      <div className="tab-content">
                        <div
                          id="Vision"
                          className="containertab tab-pane active tabcontenth"
                        >
                          <p>
                            <span className="yoo">Yoo</span>
                            <span className="foo">Foo</span>'s vision is to be a
                            respected world leader in the provision of
                            technology, software and data solutions for
                            competitive business outcomes, through increased
                            cost efficiency, and enhanced quality management
                            processes.
                          </p>
                        </div>
                        <div
                          id="Mission"
                          className="containertab tab-pane fade"
                        >
                          <p>
                            <span className="yoo">Yoo</span>
                            <span className="foo">Foo</span>'s mission is to
                            utilize a unique business model with flexibility to
                            provide clients with competitive, expert, focused, &
                            results-driven teams; to deliver the most effective,
                            efficient, technology solutions.
                          </p>
                        </div>
                        <div
                          id="Primary"
                          className="containertab tab-pane fade"
                        >
                          <div className="aboutlist">
                            <ul>
                              <li>
                                <i
                                  className="fa fa-check abouticon"
                                  aria-hidden="true"
                                ></i>
                                To help companies, especially start-ups, to
                                leverage their IT/IS resources for better
                                business outcomes
                              </li>
                              <li>
                                <i
                                  className="fa fa-check abouticon"
                                  aria-hidden="true"
                                ></i>
                                To implement interoperable decision support
                                systems that enhance patient care services,
                                patient safety, mitigate medical errors, and
                                promote continuity of care at the point of care
                              </li>
                              <li>
                                <i
                                  className="fa fa-check abouticon"
                                  aria-hidden="true"
                                ></i>
                                To conduct/perform research and analytics
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade bd-example-modal-lg"
          id="modal1"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Web Development
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-4 blueboxL popuplist">
                    <ul>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        .Net MVC
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        C#
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Java
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        VB
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        PHP
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Node.JS
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        HTML5
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        CSS
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Bootstrap
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Python
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        JQuery
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        AngularJS
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        WordPress
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Joomla
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-8 blueboxR">
                    <div className="popuppara">
                      A business's and enterprise's website presents your
                      business scope in detail to the viewers and promotes your
                      objectives with each click. We have a passion to create
                      and build websites as per your business objectives and
                      transform your business needs to a form a website. We will
                      ask your needs to get the best web friendly development
                      services with the latest trends of the market with our
                      different range of solutions.
                    </div>
                    <div className="popupimg">
                      <img
                        src="../../assets/webdev.jpg"
                        className="col-sm-6"
                        alt="Web Development"
                      ></img>
                    </div>
                    <div className="row btntmng">
                      <div className="col-md-1"></div>
                      <div className="col-md-3"></div>
                      <div className="col-md-5">
                        <button
                          href="#"
                          type="button"
                          data-dismiss="modal"
                          aria-hidden="true"
                          aria-label="Close"
                          className="btn btn-primary btn-block btntopm5"
                          onClick={() =>
                            scrollToComponent(this.ContactUs, {
                              offset: -10,
                              align: "top",
                              duration: 500,
                              ease: "inExpo",
                            })
                          }
                        >
                          <span>Request Quote</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id="modal2"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Mobile Development
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-4 blueboxL popuplist">
                    <ul>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Ios
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Android
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Windows
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Swift
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Objective-C
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        PhoneGap
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Apache Cordova
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Xamarin
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Titanium
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-8 blueboxR">
                    <div className="popuppara">
                      For your app to succeed, you need a smart business
                      strategy, a killer UI/UX design, and a talented mobile
                      development team. Good news - you've come to the right
                      source. We don't settle for okay, we shoot for
                      outstanding. That's where we can take you - if you’re
                      ready. Innovative mobile business strategies, beautiful
                      user experience designs, and powerful agile app
                      development.
                    </div>
                    <div className="popupimg">
                      <img
                        src="../../assets/mobiledev.jpg"
                        className="col-sm-6"
                        alt="Mobile Development"
                      ></img>
                    </div>
                    <div className="row btntmng">
                      <div className="col-md-1"></div>
                      <div className="col-md-3"></div>
                      <div className="col-md-5">
                        <button
                          href="#"
                          type="button"
                          data-dismiss="modal"
                          aria-hidden="true"
                          aria-label="Close"
                          className="btn btn-primary btn-block btn-pricing quote-request btntopm5"
                          onClick={() =>
                            scrollToComponent(this.ContactUs, {
                              offset: -10,
                              align: "top",
                              duration: 500,
                              ease: "inExpo",
                            })
                          }
                        >
                          <span>Request Quote</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id="modal3"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Software Architecting & Engineering
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-4 blueboxL popuplist">
                    <ul>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Software Development Life-Cycle Management (SDLC)
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Software Analysis
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Architecture & Design
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Software Integration
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Hardware Integration
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Embedded Systems
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        C, C++
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        ASM
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        C#
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Java
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Windows
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Linux
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        OSx
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        iOS
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-8 blueboxR">
                    <div className="popuppara">
                      Most companies have experienced the frustration two or
                      more software programs that are unable to share critical
                      information. Lack of software interoperability of the
                      biggest problems in business.
                      <span className="yoo"> Yoo</span>
                      <span className="foo">Foo</span> has extensive experience
                      in developing Enterprise Resource Planning (ERP) systems:
                      systems that enable multiple, independent, software
                      applications to communicate with each other throughout the
                      organization. All of our software interfaces are created
                      to meet or exceed industry standards.
                    </div>
                    <div className="popupimg">
                      <img
                        src="../../assets/softwaredev.jpg"
                        className="col-sm-6"
                        alt="Software Development Life-Cycle Management (SDLC)"
                      ></img>
                    </div>
                    <div className="row btntmng">
                      <div className="col-md-1"></div>
                      <div className="col-md-3"></div>
                      <div className="col-md-5">
                        <button
                          href="#"
                          type="button"
                          data-dismiss="modal"
                          aria-hidden="true"
                          aria-label="Close"
                          className="btn btn-primary btn-block btntopm5"
                          onClick={() =>
                            scrollToComponent(this.ContactUs, {
                              offset: -10,
                              align: "top",
                              duration: 500,
                              ease: "inExpo",
                            })
                          }
                        >
                          <span>Request Quote</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id="modal4"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Data Services
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-4 blueboxL popuplist">
                    <ul>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Infomatics
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Data research
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Data Analysis
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Reporting and Visualization
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Data Migration
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Data integration
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-8 blueboxR">
                    <div className="popuppara">
                      Most companies have experienced the frustration of trying
                      to merge data from more than one system, or data source,
                      with disappointing results. These ‘silos’ of information
                      create inefficiencies within an organization that result
                      in cumbersome work-around and hours of wasted time. In
                      fact, lack of software interoperability is one of the
                      biggest problems reported by businesses and government
                      organizations throughout the world today.
                    </div>
                    <div className="popupimg">
                      <img
                        src="../../assets/data_management.jpg"
                        className="col-sm-6"
                        alt="Data Services"
                      ></img>
                    </div>
                    <div className="row btntmng">
                      <div className="col-md-1"></div>
                      <div className="col-md-3"></div>
                      <div className="col-md-5">
                        <button
                          href="#"
                          type="button"
                          data-dismiss="modal"
                          aria-hidden="true"
                          aria-label="Close"
                          className="btn btn-primary btn-block btntopm5"
                          onClick={() =>
                            scrollToComponent(this.ContactUs, {
                              offset: -10,
                              align: "top",
                              duration: 500,
                              ease: "inExpo",
                            })
                          }
                        >
                          <span>Request Quote</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id="modal5"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Design & Creatives
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-4 blueboxL popuplist">
                    <ul>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Web design
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Graphics design
                      </li>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        UI design
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-8 blueboxR">
                    <div className="popuppara">
                      For those who have capacity of web page design, the
                      essence of web designing goes never taken a dive into the
                      beyond just embedding attractive layouts with the
                      development code. We have the capabilities and creativity
                      to make your vision, into your dream web design.
                    </div>
                    <div className="popupimg">
                      <img
                        src="../../assets/design2.jpg"
                        className="col-sm-6"
                        alt="Design & Creatives"
                      ></img>
                    </div>
                    <div className="row btntmng">
                      <div className="col-md-1"></div>
                      <div className="col-md-3"></div>
                      <div className="col-md-5">
                        <button
                          href="#"
                          type="button"
                          data-dismiss="modal"
                          aria-hidden="true"
                          aria-label="Close"
                          className="btn btn-primary btn-block btntopm5"
                          onClick={() =>
                            scrollToComponent(this.ContactUs, {
                              offset: -10,
                              align: "top",
                              duration: 500,
                              ease: "inExpo",
                            })
                          }
                        >
                          <span>Request Quote</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id="modal6"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Testing &amp; Quality Analysis
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-4 blueboxL popuplist">
                    <ul>
                      <li>
                        <i
                          className="fa fa-check okicon"
                          aria-hidden="true"
                        ></i>
                        Selenium
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-8 blueboxR">
                    <div className="popuppara">
                      Software and IT organizations wanting to ensure high
                      quality releases, need to incorporate an effective quality
                      assurance and testing program in their operations.
                    </div>
                    <div className="popupimg">
                      <img
                        src="../../assets/testing1.jpg"
                        className="col-sm-6"
                        alt="Testing &amp; Quality Analysis"
                      ></img>
                    </div>
                    <div className="row btntmng">
                      <div className="col-md-1"></div>
                      <div className="col-md-3"></div>
                      <div className="col-md-5">
                        <button
                          href="#"
                          type="button"
                          data-dismiss="modal"
                          aria-hidden="true"
                          aria-label="Close"
                          className="btn btn-primary btn-block btntopm5"
                          onClick={() =>
                            scrollToComponent(this.ContactUs, {
                              offset: -10,
                              align: "top",
                              duration: 500,
                              ease: "inExpo",
                            })
                          }
                        >
                          <span>Request Quote</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="col-md-12 col-sm-12">
            <div className="row header">
              <div className="col-md-3 col-sm-3 logoalign">
                <a href={BASE_URL}>
                  <img
                    className="img-fluid logopdng"
                    src="../../assets/yoofoo_logo.png"
                    alt="Yoofoo logo"
                  ></img>
                </a>
              </div>
              <div className="col-md-9 col-sm-12">
                <Navbar color="light" light expand="md">
                  <NavbarToggler onClick={this.toggle} />
                  <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                      <NavItem>
                        <NavLink
                          onClick={() =>
                            scrollToComponent(this.Home, {
                              offset: -100,
                              align: "top",
                              duration: 500,
                            })
                          }
                        >
                          Home
                        </NavLink>
                      </NavItem>

                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                          Services
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            onClick={() =>
                              scrollToComponent(this.DistributedAgile, {
                                offset: 0,
                                align: "top",
                                duration: 500,
                              })
                            }
                          >
                            Distributed Agile Augmentation
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              scrollToComponent(this.Technologyproject, {
                                offset: 0,
                                align: "top",
                                duration: 500,
                                ease: "inExpo",
                              })
                            }
                          >
                            Technology Project Consulting
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              scrollToComponent(this.DigitalTrans, {
                                offset: 0,
                                align: "top",
                                duration: 500,
                                ease: "inCirc",
                              })
                            }
                          >
                            Digital Transformation Strategies
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <NavItem>
                        <NavLink
                          onClick={() =>
                            scrollToComponent(this.About, {
                              offset: -100,
                              align: "top",
                              duration: 500,
                            })
                          }
                        >
                          About
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() =>
                            scrollToComponent(this.ContactUs, {
                              offset: -10,
                              align: "top",
                              duration: 500,
                              ease: "inExpo",
                            })
                          }
                        >
                          Contact Us
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </Navbar>
              </div>
            </div>
          </div>
          <div className="clear"></div>
          <div className="col-md-12 col-sm-12 paddinglr">
            <div className="row">
              <div
                className="col-md-12 col-sm-12"
                ref={(section) => {
                  this.Home = section;
                }}
              >
                <Slider autoplay={2000} className="slider-wrapper">
                  {content.map((item, index) => (
                    <div
                      key={index}
                      className="slider-content"
                      style={{
                        background: `url('${item.image}') no-repeat center center`,
                      }}
                      autoplay={5}
                    >
                      <div className="inner">
                        <h1>{item.title}</h1>
                        <p>{item.description}</p>
                        <button
                          onClick={() =>
                            scrollToComponent(this.ContactUs, {
                              offset: -10,
                              align: "top",
                              duration: 500,
                              ease: "inExpo",
                            })
                          }
                        >
                          Contact Us
                        </button>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
              <div
                className="col-md-12 col-sm-12 arrow bounce clear"
                onClick={() =>
                  scrollToComponent(this.Technologyproject, {
                    offset: 0,
                    align: "top",
                    duration: 500,
                    ease: "inCirc",
                  })
                }
              >
                <a href={BASE_URL}>
                  <img src="../../assets/arrow-down.png" alt="Arrow Down"></img>
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-sm-12 OurClients_bg">
            <div className="container">
              <div className="ourclient_hdr">
                OUR <span className="ourclient_hdr1">CLIENTS</span>
              </div>
              <div>
                <div className="row">
                  <div className="col clientLogo">
                    <img
                      src="../../assets/clients/chalkcouture1.png"
                      alt="Chalkcouture"
                    ></img>
                  </div>
                  <div className="col clientLogo">
                    <img
                      src="../../assets/clients/unicity1.png"
                      alt="Unicity"
                    ></img>
                  </div>
                  <div className="col clientLogo">
                    <img
                      src="../../assets/clients/q-sciences1.png"
                      alt="Q-Sciences"
                    ></img>
                  </div>
                  <div className="col clientLogo">
                    <img src="../../assets/clients/wink1.png" alt="Wink"></img>
                  </div>
                  <div className="col clientLogo">
                    <img
                      src="../../assets/clients/solera.png"
                      alt="Solera"
                    ></img>
                  </div>
                </div>
                <div className="row clientlogopdng">
                  <div className="col-sm-3"></div>
                  <div className="col-sm-3 clientLogo">
                    <img
                      src="../../assets/clients/dentalselect.png"
                      alt="Dentalselect"
                    ></img>
                  </div>
                  <div className="col-sm-2 clientLogo">
                    <img src="../../assets/clients/zego.png" alt="Zego"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-sm-12 review_bg">
            <div>
              <div className="review_hdr">REVIEWS</div>
              <div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <Slider autoplay={3000} className="slider-wrapper2">
                      {content_Reviews.map((item, index) => (
                        <div className="slider-content2 review_slider">
                          <div className="row">
                            <div className="col-md-5" autoplay={2}>
                              <div>
                                <div className="row reviewLpdng">
                                  <div className="col-sm-11">
                                    <div className="review_blueline"></div>
                                    <h1 className="reviews_hdr1">
                                      {item.title}
                                    </h1>
                                    <p className="reviews_hdr2">
                                      {item.description}
                                    </p>
                                    <p className="reviews_hdr2">
                                      {item.company}
                                    </p>
                                    <p className="reviews_p">
                                      {item.description1}
                                    </p>
                                  </div>
                                  <div className="col-sm-1"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <div className="row reviewLpdng_0">
                                  <div className="col-sm-10">
                                    <div className="review_blueline"></div>
                                    <h1 className="reviews_hdr1">
                                      {item.title2}
                                    </h1>
                                    <p className="reviews_hdr2">
                                      {item.description2}
                                    </p>
                                    <p className="reviews_hdr2">
                                      {item.company2}
                                    </p>
                                    <p className="reviews_p1">
                                      {item.description12}
                                    </p>
                                    <p className="reviews_p1">
                                      {item.description13}
                                    </p>
                                    <p className="reviews_p1">
                                      {item.description14}
                                    </p>
                                  </div>
                                  <div className="col-sm-1"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-sm-12">
            <div className="row">
              <div className="col-md-6 col-sm-12 aboutusbg">
                <div>
                  <div className="float_left">
                    <div data-for="soclose" data-tip="1">
                      <div className="roundedbox1">
                        <div className="clientNo1">
                          Active Clients include Small to Fortune 500
                        </div>
                      </div>
                    </div>
                    <div data-for="soclose1" data-tip="1">
                      <div className="roundedbox1">
                        <div className="clientNo5">
                          Countless Delivered Projects
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="float_left boxpdng">
                    <div data-for="soclose3" data-tip="1">
                      <div className="roundedbox">
                        <div className="clientNo3">
                          Covering Multiple Timezones
                        </div>
                      </div>
                    </div>

                    <div data-for="soclose4" data-tip="1">
                      <div className="roundedbox3">
                        <div className="clientNo7">
                          Expertise in Various Industries
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="float_left boxpdng1">
                    <div data-for="soclose5" data-tip="1">
                      <div className="roundedbox2">
                        <div className="clientNo03">
                          Experienced and High Performing Software Engineering
                          Team
                        </div>
                      </div>
                    </div>
                    <div data-for="soclose6" data-tip="1">
                      <div className="roundedbox">
                        <div className="clientNo3">
                          Offshore Onshore / Nearshore On-Premise
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="float_left boxpdng4">
                    <div data-for="soclose7" data-tip="1">
                      <div className="roundedbox circle">
                        <div className="clientNo3">
                          Digital Transformation services
                        </div>
                      </div>
                    </div>
                    <div data-for="soclose8" data-tip="1">
                      <div className="roundedbox2">
                        <div className="clientNo3">Agile Management Style</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-md-12 About">
                    <Tabs>
                      <TabList>
                        <Tab>
                          <div
                            className="heading1"
                            ref={(section) => {
                              this.About = section;
                            }}
                          >
                            About <span className="heading2">Us</span>
                          </div>
                        </Tab>
                        <Tab>
                          <div className="heading1">
                            Contact <span className="heading2">Us</span>
                          </div>
                        </Tab>
                      </TabList>
                      <TabPanel>
                        <div className="paragraphblue">
                          A respected world leader in the provision of
                          technology, software, and data solutions
                        </div>
                        <div className="paragraphgray">
                          <span className="yoo">Yoo</span>
                          <span className="foo">Foo</span> is an international
                          information technology company that specializes in
                          creating and maintaining innovative information
                          technology, data, and software solutions for a wide
                          range of needs in both private business and
                          public/governmental organization environments.
                        </div>

                        <div className="explore">
                          <a
                            href="/#/"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            data-target="#modalabout"
                          >
                            Read More About Us
                          </a>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="col-md-10 col-sm-12 getintbg1">
                          <div className="contactsubh">
                            Our team is ready to answer your questions!
                          </div>

                          <div className="form-group formpdng">
                            <input
                              type="name"
                              className="form-control"
                              placeholder="Name"
                              name="c_name"
                              required=""
                              aria-required="true"
                              value={this.state.name}
                              onChange={(txtNm) =>
                                this.setState({ name: txtNm.target.value })
                              }
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="name"
                              className="form-control"
                              placeholder="Phone"
                              name="c_name"
                              required=""
                              aria-required="true"
                              value={this.state.phone}
                              onChange={(txtPn) =>
                                this.setState({ phone: txtPn.target.value })
                              }
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="name"
                              className="form-control"
                              placeholder="Email"
                              name="c_name"
                              required=""
                              aria-required="true"
                              value={this.state.email}
                              onChange={(txtEm) =>
                                this.setState({ email: txtEm.target.value })
                              }
                            />
                          </div>
                          <div className="form-group">
                            <textarea
                              type="text"
                              className="form-control"
                              placeholder="Message"
                              required=""
                              aria-required="true"
                              value={this.state.message}
                              onChange={(txtCm) =>
                                this.setState({
                                  message: txtCm.target.value,
                                })
                              }
                            ></textarea>
                          </div>
                          <button
                            type="submit"
                            className="submitbtn"
                            onClick={() => this.sendContact()}
                          >
                            SUBMIT
                          </button>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
                <div className="row endexpl">
                  <div className="col-md-7">
                    <div
                      className=""
                      ref={(section) => {
                        this.industries = section;
                      }}
                    >
                      <div>
                        <div className="headingAbout_In">
                          Industry
                          <span className="headingAbout1_In">Expertise</span>
                        </div>
                      </div>
                      <Slider autoplay={2000} className="slider-wrapper3">
                        {Industry_Expertise.map((item, index) => (
                          <div
                            key={index}
                            className="slider-content1"
                            autoplay={3}
                          >
                            <h1 className="Industry_subh1">
                              {item.Industry_title}
                            </h1>
                            <h1 className="Industry_subh2">
                              {item.Industry_title1}
                            </h1>
                            <p className="Industry_logo Industry_lpdng">
                              <img alt="profile" src={item.Industry_logo} />
                            </p>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-sm-12">
            <div className="container-fluid content_mrg">
              <div className="row">
                <div className="col-md-3 whatwedo_hdr">What We Do</div>
                <div className="col-md-9 whatwedo_hd1">
                  We build to your business needs, asking questions before
                  providing answers.
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-md-6 Design_tpdng">
                      <div className="card">
                        <h5 className="card-title cardhdr">
                          <div className="lineblue"></div> Design
                        </h5>
                        <img src="../../assets/design.png" alt="design" />
                        <div className="card-body">
                          <p className="card-text cardhdr">
                            Plug into years of experience in architecture and
                            visual design in building positive experiences for
                            businesses and end-users alike.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 Build_tpdng">
                      <div className="card">
                        <h5 className="card-title cardhdr">
                          <div className="lineblue"></div> Build
                        </h5>
                        <img src="../../assets/build.png" alt="build"></img>
                        <div className="card-body">
                          <p className="card-text cardhdr">
                            Awaken software and applications, join your needs,
                            ideas, and visions with our lean and agile process.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 whatwedo_hdrsub">
                    FROM DISCOVERY TO DEPLOYMENT, REALIZE YOUR PERFECT PRODUCT
                    WITH SOFTWARE DEVELOPMENT CONSULTING
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="col-md-12 whatwedo_hdrsub1">
                    WE ARE GLOBAL, YOU ARE TOO WITH DISTRIBUTED AGILE SOFTWARE
                    DEVELOPMENT.
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-12 Scale_tpdng">
                      <div className="card">
                        <h5 className="card-title cardhdr">
                          <div className="lineblue"></div> Scale
                        </h5>
                        <img src="../../assets/scale.png" alt="scale"></img>
                        <div className="card-body">
                          <p className="card-text cardhdr">
                            Take advantange of our scalable solutions and
                            trusted architecture to support your critical
                            business operations.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="card Secure_tpdng">
                        <h5 className="card-title cardhdr">
                          <div className="lineblue"></div> Secure
                        </h5>
                        <img src="../../assets/secure.png" alt="secure"></img>
                        <div className="card-body">
                          <p className="card-text cardhdr">
                            Security is a fundamental part in our development
                            process. It is always our goal to provide secure
                            solutuions that are user-friendly.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-sm-12 paytopp">
            <div className="container-fluid content_mrg1">
              <div className="row">
                <div className="col-md-3 whatwedo_hdr">Our Services</div>
                <div className="col-md-9 whatwedo_hd1">
                  Scale up development and complete that new initiative without
                  a long-term resource commitment.
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div
                    className="cardpdng_img"
                    ref={(section) => {
                      this.DistributedAgile = section;
                    }}
                  >
                    <img
                      src="../../assets/ourservice.jpg"
                      alt="Distributed Agile Augmentation"
                    ></img>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div
                    className="cardpdng_img"
                    ref={(section) => {
                      this.Technologyproject = section;
                    }}
                  >
                    <img
                      src="../../assets/ourservice1.jpg"
                      alt="Technology Project Consulting"
                    ></img>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div
                    className="cardpdng_img"
                    ref={(section) => {
                      this.DigitalTrans = section;
                    }}
                  >
                    <img
                      src="../../assets/ourservice2.jpg"
                      alt="DigitalTransformation Strategies"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div>
                    <div className="cardpdng">
                      <div className="row">
                        <div className="cardbr"></div>
                        <div className="col-md-10 paddingleft0">
                          <div className="card cardbr1">
                            <div>
                              <i className="fa fa-laptop icon1"></i>
                            </div>
                            <h5 className="card-title cardhdr">
                              Web Development
                            </h5>
                            <p className="pdngtp">
                              A business and enterprise website presents your
                              business scope in detail to the viewers and
                              promotes your objectives with each click. We have
                              a...
                            </p>
                            <div>
                              <a
                                href={BASE_URL}
                                data-toggle="modal"
                                data-target=".bd-example-modal-lg"
                                data-target="#modal1"
                              >
                                Read More
                                <i
                                  className="fa fa-angle-double-right readmore"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cardpdng">
                      <div className="row">
                        <div className="cardbr"></div>
                        <div className="col-md-10 paddingleft0">
                          <div className="card cardbr1">
                            <div>
                              <i className="fa fa-mobile icon1"></i>
                            </div>
                            <h5 className="card-title cardhdr">
                              Mobile Development
                            </h5>
                            <p>
                              Going mobile? We got you covered. For your app to
                              succeed, you need a smart business strategy, a
                              killer UI/UX design, and a talented mobile...
                            </p>
                            <div>
                              <a
                                href={BASE_URL}
                                data-toggle="modal"
                                data-target=".bd-example-modal-lg"
                                data-target="#modal2"
                              >
                                Read More
                                <i
                                  className="fa fa-angle-double-right readmore"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="cardpdng">
                    <div className="row">
                      <div className="cardbr"></div>
                      <div className="col-md-10 paddingleft0">
                        <div className="card cardbr1">
                          <div>
                            <i
                              className="fa fa-cog icon1"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="card-title cardhdr">
                            Software Architecting & Engineering
                          </h5>
                          <p>
                            Tired of that house of cards system? Our top
                            architects and engineering team have your back. Most
                            companies have experienced the frustration of...
                          </p>
                          <div>
                            <a
                              href={BASE_URL}
                              data-toggle="modal"
                              data-target=".bd-example-modal-lg"
                              data-target="#modal3"
                            >
                              Read More
                              <i
                                className="fa fa-angle-double-right readmore"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cardpdng">
                    <div className="row">
                      <div className="cardbr"></div>
                      <div className="col-md-10 paddingleft0">
                        <div className="card cardbr1">
                          <div>
                            <i
                              className="fa fa-database icon1"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="card-title cardhdr">Data Services</h5>
                          <p>
                            Do more with your data than just analytics. Look
                            into the future with big data and infomatics. Most
                            companies have experienced the frustration of...
                          </p>
                          <div>
                            <a
                              href={BASE_URL}
                              data-toggle="modal"
                              data-target=".bd-example-modal-lg"
                              data-target="#modal4"
                            >
                              Read More
                              <i
                                className="fa fa-angle-double-right readmore"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="cardpdng">
                    <div className="row">
                      <div className="cardbr"></div>
                      <div className="col-md-10 paddingleft0">
                        <div className="card cardbr1">
                          <div>
                            <i className="fa icon1">&#xf03e;</i>
                          </div>
                          <h5 className="card-title cardhdr">
                            Design & Creatives
                          </h5>
                          <p className="pdngtp">
                            Looking for some of the most creative minds? We have
                            them here. For those who have been hiding under the
                            hood and have never taken a dive into the...
                          </p>
                          <div>
                            <a
                              href={BASE_URL}
                              data-toggle="modal"
                              data-target=".bd-example-modal-lg"
                              data-target="#modal5"
                            >
                              Read More
                              <i
                                className="fa fa-angle-double-right readmore"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cardpdng">
                    <div className="row">
                      <div className="cardbr"></div>
                      <div className="col-md-10 paddingleft0">
                        <div className="card cardbr1">
                          <div>
                            <i className="fa icon1">&#xf201;</i>
                          </div>
                          <h5 className="card-title cardhdr">
                            Testing &amp; Quality Analysis
                          </h5>
                          <p>
                            Comprehensive software testing &amp; QA resources.
                            Software and IT organizations wanting to ensure high
                            quality releases need to...
                          </p>
                          <div>
                            <a
                              href={BASE_URL}
                              data-toggle="modal"
                              data-target=".bd-example-modal-lg"
                              data-target="#modal6"
                            >
                              Read More
                              <i
                                className="fa fa-angle-double-right readmore"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-sm-12">
            <div className="container-fluid">
              <div
                className="row innerContent"
                ref={(section) => {
                  this.ContactUs = section;
                }}
              >
                <div className="row">
                  <div className="col-md-7">
                    <div>
                      <div className="lefthdrbluline"></div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 col-sm-12 headingAbout">
                        Contact <span className="headingAbout1">Us</span>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <div className="addresstopp">
                          <div className="row addrlp">
                            <div className="col-sm-12 col-md-7">
                              <div className="address_hdr">
                                <i className="fas fa-map-marker-alt"></i>
                                &nbsp; Address
                              </div>
                              <div>
                                <div className="address_hdr1">
                                  United States
                                </div>
                                <div className="address_p">YooFoo, LLC.</div>
                                <div className="address_p">
                                  9192 South 300 West Suite 3
                                </div>
                                <div className="address_p">
                                  Sandy, Utah 84070
                                </div>
                              </div>
                              <div>
                                <div className="address_hdr1">India</div>
                                <div className="address_p">
                                  Yoofoo Technologies India Private Limited
                                </div>
                                <div className="address_p">
                                  Suite S7, Vatika Business Centre, 6th Floor,
                                </div>
                                <div className="address_p">
                                  Omega-C Block, Divyasree Building,
                                </div>
                                <div className="address_p">
                                  Hitech City Rd, Kondapur, Hyderabad
                                </div>
                                <div className="address_p">
                                  Telangana - 500081
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-5">
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="address_hdr adrbtm15">
                                    <i className="fas fa-phone-volume"></i>
                                    &nbsp; Phone
                                  </div>
                                  <div className="address_p adrbtm15">
                                    <div>
                                      <div>
                                        <img
                                          className="flagdiv"
                                          src="../../assets/usa.png"
                                          alt="ourservice2"
                                        ></img>
                                      </div>
                                    </div>
                                    +1-800-741-8264
                                  </div>
                                  <div className="address_p">
                                    <div>
                                      <img
                                        className="flagdiv"
                                        src="../../assets/india.png"
                                        alt="ourservice2"
                                      ></img>
                                    </div>
                                    040-71055788
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="contactfrom">
                      <div className="Getinhdr">Get In Touch</div>
                      <div className="contactsubh">
                        Our team is ready to answer your questions!
                      </div>
                      <div className="form-group formpdng">
                        <input
                          type="name"
                          className="form-control"
                          placeholder="Name"
                          name="c_name"
                          required=""
                          aria-required="true"
                          value={this.state.name}
                          onChange={(txtNm) =>
                            this.setState({ name: txtNm.target.value })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Phone"
                          name="c_name"
                          required=""
                          aria-required="true"
                          value={this.state.phone}
                          onChange={(txtPn) =>
                            this.setState({ phone: txtPn.target.value })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="Email"
                          className="form-control"
                          placeholder="Email"
                          name="c_name"
                          required=""
                          aria-required="true"
                          value={this.state.email}
                          onChange={(txtEm) =>
                            this.setState({ email: txtEm.target.value })
                          }
                        />
                      </div>

                      <div className="form-group">
                        <textarea
                          type="text"
                          className="form-control"
                          placeholder="Message"
                          required=""
                          aria-required="true"
                          value={this.state.message}
                          onChange={(txtCm) =>
                            this.setState({
                              message: txtCm.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                      <button
                        type="submit"
                        className="submitbtn"
                        onClick={() => this.sendContact()}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footerscreen />
          <div>
            <ScrollUpButton TransitionClassName="AnyClassForTransition" />
          </div>
        </div>
      </div>
    );
  }
}
